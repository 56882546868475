import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/Layout'
import ProductView from 'src/views/product'
import { ProductPageProvider } from 'src/contexts/ProductPageContext'
import type { Product } from 'src/typings'

interface IPage extends PageProps {
  data: { product: Product }
}

const Page: FC<IPage> = (props) => {
  const canonicalMetatag = `https://www.avon.com.br/${props.location.pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace('/p/', '/p')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
      </Helmet>
      <Layout>
        <ProductPageProvider
          product={props.data.product}
          slug={props.params.slug}
        >
          <ProductView {...props} />
        </ProductPageProvider>
      </Layout>
    </>
  )
}

export const querySSG = graphql`
  query ServerProductPageQuery($id: String!) {
    product: storeProduct(id: { eq: $id }) {
      id: productId
      ...ProductViewFragment_product
    }
  }
`

export default Page
